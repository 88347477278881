const TERMS_AND_CONDITIONS_URL =
  'https://drive.google.com/file/d/1SoFl10bsuD0DPTImEIMtH0rqC8Qte6tn/preview';

const enableTacPage = () => {
  const commingSoon = `<div>
  <h3>Terms and Conditions</h3>
  <p>Coming Soon...</p>
  </div>`;
  // const iframeForPDF = `<iframe src="${tacpdf}#toolbar=0&navpanes=1" height="100%"  frameBorder="5"
  // width="100%"></iframe>`;

  const googleDriveIframe = `<iframe src="${TERMS_AND_CONDITIONS_URL}" width="100%" height="100%" allow="autoplay"></iframe>`;
  document.getElementById('home-content').style = 'display:none;';

  document.getElementById('tac-content').innerHTML = googleDriveIframe;

  document.getElementById('tac-content').style =
    'display:flex; visibility:visible;height: 900px;';

  setTimeout(
    () => document.getElementById('tac-content').scrollIntoView(),
    500
  );
};

const enableHomePage = () => {
  document.getElementById('tac-content').style = 'display:none;';
  document.getElementById('home-content').style =
    'display:unset; visibility:visible;';
};

const onRouteChange = (e) => {
  if (location.pathname === '/terms' || location.hash === '#term') {
    enableTacPage();
  } else {
    enableHomePage();
  }
};
window.onload = onRouteChange;

window.onpopstate = onRouteChange;

window.onhashchange = (e) => {
  const hash = e.newURL.split('#')[1];
  const tacStyle = document.getElementById('tac-content').style.cssText;
  if (!tacStyle.includes('display:none') && hash !== 'term') {
    enableHomePage();
  } else if (hash === 'term') {
    enableTacPage();
  }
};
